import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import { baseUrl } from './common';


export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return <Component {...props} router={{ location, navigate, params }} />;
    }
    return ComponentWithRouterProp;
  };

class PersonInfoValidated extends Component {
    emptyItem={
        email:'',
        fullName:'',
        whatsappNo:''
    };

    constructor(props){
        super(props);
        this.state={
            item: this.emptyItem
        };
    }

    async componentDidMount() {
        const client = await (await fetch(`${baseUrl}/personInfo/${this.props.router.params.id}`)).json();
        this.setState({item: client});
    }

    render() {
        const {item} = this.state;
        const title = <h2>Nomor terkonfirmasi</h2>;
    
        return(
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9 w-50">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div>
                <div className="row">
                  <div >
                    <div className="p-5">
        <div>
            <Container style={{height:'100px'}}>
                {title}
                <div style={{display : 'block'}}>
                    Terima kasih. Nomor {item.fullName} ({item.email}) adalah <strong>{item.whatsappNo}</strong>
                </div>
            </Container>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        )
    }
}
export default withRouter(PersonInfoValidated);