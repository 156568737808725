import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PersonInfoEdit from './PersonInfoEdit';
import PersonInfoValidate from './PersonInfoValidate';
import PersonInfoValidated from './PersonInfoValidated';
import PersonInfoConfirm from './PersonInfoConfirm';
import UnconfirmedPersonList from './UnconfirmedPersonList';
import NotifLogList from './NotifLogList';
import Login from './Login';
import NotifLogDetail from './NotifLogDetail';

class App extends Component {
  render() {
    return (
          <Routes>
            <Route path='/login' element={<Login/>}/>
            <Route path='/upl' element={<UnconfirmedPersonList/>}/>
            <Route path='/nl' element={<NotifLogList/>}/>
            <Route path='/nl/:id' element={<NotifLogDetail/>}/>
            <Route path='/:id/confirm' element={<PersonInfoConfirm/>}/>
            <Route path='/:id/validate' element={<PersonInfoValidate/>}/>
            <Route path='/:id/validated' element={<PersonInfoValidated/>}/>
            <Route path='/:id' element={<PersonInfoEdit/>}/>
          </Routes>
    )
  }
}

export default App;