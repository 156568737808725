import React, { Component } from 'react';
import { Form, Input } from 'reactstrap';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import authHeader from './services/auth.header';
import { baseUrl } from './common';
import AdminLayout from './components/AdminLayout';

export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return <Component {...props} router={{ location, navigate, params }} />;
    }
    return ComponentWithRouterProp;
};

class NotifLogList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notif_logs: [],
            searchParam: {
                status:'',
                email:'',
                whatsappNo:'',
                page:0
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }
    
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let notif_logs = this.state.notif_logs;
        let item = {...this.state.searchParam};
        item[name] = value;
        this.setState({...this.state, searchParam: item});
    }

    handlePrevious(event) {
        event.preventDefault();
        let page = this.state.currentPage;
        if(page > 0) page -= 1;
        let item = {...this.state.searchParam};
        item['page'] = page;
        let newState = {...this.state, searchParam: item };
        this.setState(newState,()=>{this.fetchData()});
    }

    handleNext(event) {
        event.preventDefault();
        let page = this.state.currentPage;
        let totalPages = this.state.totalPages;
        if(page < totalPages-1) page += 1;
        let item = {...this.state.searchParam};
        item['page'] = page;
        let newState = {...this.state, searchParam: item };
        this.setState(newState, ()=>{this.fetchData()});
    }

    async handleSubmit(event) {
        event.preventDefault();

        let page = 0;
        let item = {...this.state.searchParam};
        item['page'] = page;
        let newState = {...this.state, searchParam: item };
        this.setState(newState, ()=>{this.fetchData()});
    }

    fetchData = () => {
        const sp = new URLSearchParams();
        sp.append('status', this.state.searchParam.status);
        sp.append('email', this.state.searchParam.email);
        sp.append('whatsappNo', this.state.searchParam.whatsappNo);
        if(this.state.searchParam.page) sp.append('page', this.state.searchParam.page);
        const s = sp.toString();
        
        fetch(`${baseUrl}/notifLog?${s}`, { headers: authHeader() })
        .then(response => response.json())
        .then(data => {
            if(data.error) {
                this.setState({redirectToLogin: true});
            } else {
            this.setState({
                notif_logs: data.notifLogs,
                totalPages: data.totalPages,
                currentPage: data.currentPage,
                totalItems: data.totalItems,
                searchParam: this.state.searchParam})
            }
        });
    }

    pageBar = () => {
        return <Pagination>
            <PaginationItem>
                <PaginationLink previous href='#' onClick={this.handlePrevious}/>
            </PaginationItem>
            <div>
            <PaginationItem>
                <PaginationLink>
                Halaman {this.state.currentPage+1} dari total {this.state.totalPages}
                </PaginationLink>
            </PaginationItem>
            </div>
            <PaginationItem>
                <PaginationLink next href='#' onClick={this.handleNext}/>
            </PaginationItem>
        </Pagination>
    }

    mainContent = () => {
    }

    wrapper = (content) => {
    }
    render() {
        if(this.state.redirectToLogin) {
            return this.props.router.navigate('/login');
        }
        const emailDomainRegex = /@(?:[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?/
        const {notif_logs} = this.state;
        const notif_log_list = notif_logs.map(notif_log => {
            return <tr key={notif_log.id}>
                <td>{notif_log.status}</td>
                <td >{notif_log.emailNotifTitle}</td>
                <td >{notif_log.emailNotifTo.replace(emailDomainRegex, '@...')}</td>
                <td >{notif_log.emailNotifCc.replace(emailDomainRegex, '@...')}</td>
                <td>{new Date(notif_log.emailReceivedAt).toLocaleString()}</td>
                <td style={{whiteSpace: 'nowrap'}}>{notif_log.whatsappNumber}</td>
                <td>{new Date(notif_log.whatsappSentAt).toLocaleString()}</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" tag={Link} to={"/nl/" + notif_log.id}>Detail</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });
        return (
            <AdminLayout>
                <Container fluid>
                    <h3>Notification Log</h3>
            <div>
            {this.pageBar()}
            </div>
                    <Form onSubmit={this.handleSubmit}>

                    <Table className="mt-4 small table-striped" style={{fontSize:'13px'}}>
                        <thead>
                        <tr>
                            <th >Status</th>
                            <th >Email Subject</th>
                            <th >Email To</th>
                            <th >Email Cc</th>
                            <th >Email Received At</th>
                            <th >Whatsapp No</th>
                            <th >Whatsapp Sent At</th>
                            <th >Actions</th>
                        </tr>
                        <tr>
                            <th >
                                <Input className='form-select-sm' type="select" name="status" value={this.state.searchParam.status}
                                onChange={this.handleChange}>
                                    <option value="">any</option>
                                    <option value="success">Success</option>
                                    <option value="error">Error</option>
                                </Input>
                            </th>
                            <th ></th>
                            <th colSpan={2}>
                            <Input className='form-control-sm' type="text" name="email" id="email" placeholder='search by email' value={this.state.searchParam.email || ''}
                                onChange={this.handleChange}/>
                                </th>
                            <th ></th>
                            <th >
                            <Input className='form-control-sm' type="text" name="whatsappNo" id="whatsappNo" placeholder='search by WA No' value={this.state.searchParam.whatsappNo || ''}
                                onChange={this.handleChange}/>
                            </th>
                            <th ></th>
                            <th >
                    <ButtonGroup>
                            <Button color="primary" type="submit">Search</Button>
                    </ButtonGroup>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {notif_log_list}
                        </tbody>
                    </Table>
                    </Form>
                </Container>
            </AdminLayout>
        );
    }
}

export default withRouter(NotifLogList);