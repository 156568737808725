import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import { baseUrl } from './common';


export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return <Component {...props} router={{ location, navigate, params }} />;
    }
    return ComponentWithRouterProp;
  };

class PersonInfoEdit extends Component {
    emptyItem={
        email:'',
        fullName:'',
        whatsappNo:''
    };

    constructor(props){
        super(props);
        this.state={
            item: this.emptyItem
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const client = await (await fetch(`${baseUrl}/personInfo/${this.props.router.params.id}`)).json();
        this.setState({item: client});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
    
        await fetch(baseUrl+'/personInfo/' + item.email, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            body: JSON.stringify(item),
        });

        this.props.router.navigate('/'+item.email+'/validate');
    }

    render() {
        const {item} = this.state;
        const title = <h2>Edit Whatsapp No</h2>;

        if((item.status||'200') !=='200') {
            return(
        <div>
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9 w-50">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div>
                <div className="row">
                  <div >
                    <div className="p-5">
            <Container>DATA NOT FOUND</Container>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
            )
        }
    
        return (
        <div>
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9 w-50">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div>
                <div className="row">
                  <div >
                    <div className="p-5">
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input readOnly disabled name="email" id="email" value={item.email || ''} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="fullName">Nama Lengkap</Label>
                        <Input readOnly disabled name="fullName" id="fullName" value={item.fullName || ''} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="whatsappNo">Whatsapp No</Label>
                        <Input type="text" name="whatsappNo" id="whatsappNo" value={item.whatsappNo || ''}
                               onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        )
    }
}
export default withRouter(PersonInfoEdit);