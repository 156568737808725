import React, {Component} from 'react';
import authHeader from './services/auth.header';
// import {Link, withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import {baseUrl} from './common';
import AdminLayout from './components/AdminLayout';


export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return <Component {...props} router={{ location, navigate, params }} />;
    }
    return ComponentWithRouterProp;
};

class NotifLogDetail extends Component {
    emptyItem={
        id:0,
        status:'',
        emailNotifTitle:'',
        emailNotifTo:'',
        emailNotifCc:'',
        emailNotifBody:'',
        emailReceivedAt:'',
        whatsappNumber:'',
        whatsappMessage:'',
        whatsappSentAt:'',
        note:''
    };

    constructor(props){
        super(props);
        this.state={
            item: this.emptyItem
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const client = await (await fetch(`${baseUrl}/notifLog/${this.props.router.params.id}`, {headers: authHeader()})).json();
        if(client.status==401) return this.props.router.navigate('/login'); 
        this.setState({item: client});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
    
        await fetch(baseUrl+'/notifLog/' + item.id + '/resend', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify(item),
        });
        this.props.router.navigate('/nl');
    }

    render() {
        const {item} = this.state;
        const title = <h2>Detail Notification Log</h2>;
    
        return <AdminLayout>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="status">Status</Label>
                        <Input readOnly disabled name="status" id="status" value={item.status || ''}
                               />
                    </FormGroup>
                    <FormGroup>
                        <Label for="emailNotifTitle">Email Subject</Label>
                        <Input readOnly disabled name="emailNotifTitle" id="emailNotifTitle" value={item.emailNotifTitle || ''}
                               />
                    </FormGroup>
                    <FormGroup>
                        <Label for="emailNotifTo">To</Label>
                        <Input readOnly disabled name="emailNotifTo" id="emailNotifTo" value={item.emailNotifTo || ''}
                               />
                    </FormGroup>
                    <FormGroup>
                        <Label for="emailNotifCc">CC</Label>
                        <Input readOnly disabled name="emailNotifCc" id="emailNotifCc" value={item.emailNotifCc || ''}
                               />
                    </FormGroup>
                    <FormGroup>
                        <Label for="emailNotifBody">Email Body</Label>
                        <Input type="textarea" rows={10} readOnly disabled name="emailNotifBody" id="emailNotifBody" value={item.emailNotifBody || ''}
                               />
                    </FormGroup>
                    <FormGroup>
                        <Label for="emailReceivedAt">Email Received At</Label>
                        <Input readOnly disabled name="emailReceivedAt" id="emailReceivedAt" value={item.emailReceivedAt ? new Date(item.emailReceivedAt).toLocaleString() : ''}
                               />
                    </FormGroup>
                    <FormGroup>
                        <Label for="whatsappSentAt">Whatsapp Sent At</Label>
                        <Input readOnly disabled name="whatsappSentAt" id="whatsappSentAt" value={item.whatsappSentAt ? new Date(item.whatsappSentAt).toLocaleString() : ''}
                               />
                    </FormGroup>
                    <FormGroup>
                        <Label for="note">Error</Label>
                        <Input readOnly disabled name="note" id="note" value={item.note}
                               />
                    </FormGroup>
                    <FormGroup>
                        <Label for="whatsappNumber">Whatsapp No</Label>
                        <Input type="text" name="whatsappNumber" id="whatsappNumber" value={item.whatsappNumber || ''}
                               onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="whatsappMessage">Whatsapp Message</Label>
                        <Input type="textarea" rows={5} name="whatsappMessage" id="whatsappMessage" value={item.whatsappMessage || ''}
                               onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>
                        <Button color="secondary" tag={Link} to="/nl">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
            </AdminLayout>
    }
}
export default withRouter(NotifLogDetail);