import React, { Component } from 'react';
import { Form, Input } from 'reactstrap';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import authHeader from './services/auth.header';
import { baseUrl } from './common';
import AdminLayout from './components/AdminLayout';

export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return <Component {...props} router={{ location, navigate, params }} />;
    }
    return ComponentWithRouterProp;
};

class UnconfirmedPersonList extends Component {

    constructor(props) {
        const person = {
            id: 1,
            email: 'mas.reza@gmail.com',
            fullName: 'Mas Reza',
            whatsappNo: '08123123',
            nik: '123123213',
            confirmed: false,
        }
        super(props);
        this.state = {
            persons: [person],
            searchParam: {
                email:'',
                page:0
            },
            selectedPersons: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.onItemCheck = this.onItemCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.sendConfirm = this.sendConfirm.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }
    
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let persons = this.state.persons;
        let item = {...this.state.searchParam};
        item[name] = value;
        this.setState({...this.state, searchParam: item});
    }

    handlePrevious(event) {
        event.preventDefault();
        let page = this.state.currentPage;
        if(page > 0) page -= 1;
        let item = {...this.state.searchParam};
        item['page'] = page;
        let newState = {...this.state, searchParam: item };
        this.setState(newState,()=>{this.fetchData()});
    }

    handleNext(event) {
        event.preventDefault();
        let page = this.state.currentPage;
        let totalPages = this.state.totalPages;
        if(page < totalPages-1) page += 1;
        let item = {...this.state.searchParam};
        item['page'] = page;
        let newState = {...this.state, searchParam: item };
        this.setState(newState, ()=>{this.fetchData()});
    }

    async handleSearch(event) {
        event.preventDefault();

        let page = 0;
        let item = {...this.state.searchParam};
        item['page'] = page;
        let newState = {...this.state, searchParam: item };
        this.setState(newState, ()=>{this.fetchData()});
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.sendConfirm();
    }

    fetchData = () => {
        const sp = new URLSearchParams();
        sp.append('email', this.state.searchParam.email);
        if(this.state.searchParam.page) sp.append('page', this.state.searchParam.page);
        const s = sp.toString();
        
        fetch(`${baseUrl}/personInfo/unconfirmed?${s}`, { headers: authHeader() })
        .then(response => response.json())
        .then(data => {
            if(data.error) {
                this.setState({redirectToLogin: true});
            } else {
            this.setState({
                persons: data.personInfos,
                totalPages: data.totalPages,
                currentPage: data.currentPage,
                totalItems: data.totalItems,
                searchParam: this.state.searchParam})
            }
        });
    }

    sendConfirm = () => {
        const {selectedPersons} = this.state;
        const body = {emails:selectedPersons.map(person=>person.email)};
        fetch(baseUrl+'/personInfo/bulkConfirm', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        }).then(response => response.json())
        .then(json => {
            if(json.error){
                alert(json.error);
            } else {
                this.fetchData();
            }
        })
    }

    onItemCheck(e, item) {
        let tempList = this.state.persons;
        tempList.map((person) => {
            if(person.id === item.id) person.selected = e.target.checked;
            return person;
        });
        let selectedList = this.state.selectedPersons;
        if(e.target.checked) {
            let found = tempList.find(person => person.id === item.id);
            selectedList = [...selectedList, found];
        } else {
            selectedList = selectedList.filter(person => person.id !== item.id);
        }

        let newState = {...this.state, persons: tempList, selectedPersons: selectedList };
        this.setState(newState);
    }
    pageBar = () => {
        return <Pagination>
            <PaginationItem>
                <PaginationLink previous href='#' onClick={this.handlePrevious}/>
            </PaginationItem>
            <div>
            <PaginationItem>
                <PaginationLink>
                Halaman {this.state.currentPage+1} dari total {this.state.totalPages}
                </PaginationLink>
            </PaginationItem>
            </div>
            <PaginationItem>
                <PaginationLink next href='#' onClick={this.handleNext}/>
            </PaginationItem>
        </Pagination>
    }
    render() {
        if(this.state.redirectToLogin) {
            return this.props.router.navigate('/login');
        }
        const {persons} = this.state;

        const person_list = persons.map(person => {
            return <tr key={person.email}>
                <td>
                    <input type='checkbox' checked={person.selected} onChange={e => this.onItemCheck(e, person)}/>
                </td>
                <td>{person.email}</td>
                <td >{person.fullName}</td>
                <td >{person.whatsappNo}</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" tag={Link} to={"/nl/" + person.id}>Detail</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });

        return (
            <AdminLayout>
            <div className='card'>
                <Container fluid>
                    <h3>Unconfirmed Person</h3>

            <div>
            {this.pageBar()}
            </div>
                    <Form onSubmit={this.handleSubmit}>

                    <Table className="">
                        <thead>
                        <tr>
                            <th > </th>
                            <th >Email</th>
                            <th >Nama</th>
                            <th >Whatsapp No</th>
                            <th >Actions</th>
                        </tr>
                        <tr>
                            <th ></th>
                            <th >
                            <Input type="text" name="email" id="email" placeholder='search by email' value={this.state.searchParam.email || ''}
                                onChange={this.handleChange}/>
                            </th>
                            <th ></th>
                            <th >
                            </th>
                            <th >
                    <ButtonGroup>
                            <Button color="primary" type='button' onClick={this.handleSearch}>Search</Button>
                    </ButtonGroup>
                            </th>
                        </tr>
                        </thead>
                        <tbody style={{fontSize:'13px'}}>
                        {person_list}
                        </tbody>
                    </Table>
                    <ButtonGroup>
                        <Button color="primary" type="submit">Send Confirmation</Button>
                    </ButtonGroup>
                    </Form>
                </Container>
            </div>
            </AdminLayout>
        );
    }
}

export default withRouter(UnconfirmedPersonList);