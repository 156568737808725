import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import { baseUrl } from './common';


export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return <Component {...props} router={{ location, navigate, params }} />;
    }
    return ComponentWithRouterProp;
  };

class PersonInfoValidate extends Component {
    emptyItem={
        email:'',
        emailOtp:'',
        waOtp:''
    };

    constructor(props){
        super(props);
        this.state={
            item: this.emptyItem
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        let item = this.state;
        item.email = this.props.router.params.id;
        this.setState({item});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
    
        await fetch(baseUrl+'/personInfo/' + item.email + '/validate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item),
        }).then(response => response.json())
        .then(json => {
            if(json.error){
                alert(json.error);
            } else {
                this.props.router.navigate(`/${item.email}/validated`);
            }
        })
    }

    render() {
        const {item} = this.state;
        const title = <h2>Validasi OTP</h2>;
    
        return (
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9 w-50">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div>
                <div className="row">
                  <div >
                    <div className="p-5">
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="emailOtp">Email OTP</Label>
                        <Input type="text" name="emailOtp" id="emailOtp" value={item.emailOtp || ''}
                               onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="waOtp">Whatsapp OTP</Label>
                        <Input type="text" name="waOtp" id="waOtp" value={item.waOtp || ''}
                               onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup style={{display:'flex', justifyContent:'space-around'}}>
                        <Button color="secondary" tag={Link} to={'/'+item.email}>Cancel</Button>
                        <Button color="primary" type="submit">Save</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        )
    }
}
export default withRouter(PersonInfoValidate);