import React, { Component } from 'react';
// import {Link, withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AuthService from './services/auth.service'


export const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
};

class Login extends Component {
  emptyItem = {
    email: "",
    password: ""
  };

  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: ""
    };

  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    // this.form.validateAll();

    if (/*this.checkBtn.context._errors.length === 0*/true) {
      AuthService.login(this.state.email, this.state.password).then(
        () => {
          this.props.router.navigate("/nl");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9 w-50">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div>
                <div className="row">
                  <div >
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Login</h1>
                      </div>

                      <Form
                        className="user"
                        onSubmit={this.handleLogin}
                        ref={c => {
                          this.form = c;
                        }}
                      >
                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            type="text"
                            className="form-control form-control-user"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="password">Password</Label>
                          <Input
                            type="password"
                            className="form-control form-control-user"
                            name="password"
                            value={this.state.password}
                            onChange={this.onChangePassword}
                          />
                        </FormGroup>

                        <hr />
                        <FormGroup className='form-group'>
                          <Button color="primary" type="submit" className="btn btn-primary btn-user btn-block" disabled={this.state.loading}>Login</Button>{' '}
                        </FormGroup>

                        {this.state.message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {this.state.message}
                            </div>
                          </div>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);